var openWidgetSub = document.querySelectorAll(".open-widget-sub");
var closeWidgetSub = document.querySelectorAll(".close-widget-sub");

for (var i = 0; i < openWidgetSub.length; i++) {
  openWidgetSub[i].onclick = function () {
    let openIcon = this;
    let closeIcon = this.parentNode.children[1];
    let parent = this.parentNode.parentNode.parentNode;

    for (var i = 0; i < parent.children.length; i++) {
      if (parent.children[i].classList.contains("widget-menu-sub")) {
        parent.children[i].style.display = "block";
        closeIcon.style.display = "block";
        openIcon.style.display = "none";
      }
    }
  }
}

for (var i = 0; i < closeWidgetSub.length; i++) {
  closeWidgetSub[i].onclick = function () {
    let closeIcon = this;
    let openIcon = this.parentNode.children[0];
    let parent = this.parentNode.parentNode.parentNode;

    for (var i = 0; i < parent.children.length; i++) {
      if (parent.children[i].classList.contains("widget-menu-sub")) {
        parent.children[i].style.display = "none";
        closeIcon.style.display = "none";
        openIcon.style.display = "block";
      }
    }
  }
}
